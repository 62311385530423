import React from 'react';
import styled from 'styled-components';
import { colors } from '../theme/index';
import ThemeProvider from 'stoomlink-commons-ui/ThemeProvider/ThemeProvider';
import Title from 'stoomlink-commons-ui/Title/Title';
import BodyText from 'stoomlink-commons-ui/BodyText/BodyText';
import Layout from '../layouts/Layout';
import Head from '../layouts/Head';
import Container from '../layouts/Container';
import Footer from '../components/Footer';
import data from '../../content/team';
import JobCard from '../components/JobCard';

const CardContainer = styled.div`
  margin-top: 64px;

  h1 {
    margin-bottom: 24px;
  }

  @media screen and (max-width: 780px){
    margin-top: 64px;
  }
`;

const About = styled.div`
  margin-top: 64px;
  margin-bottom: 128px;

  @media screen and (max-width: 780px){
    margin-bottom: 64px;
  }
`;

const Link = styled.a`
  color: ${colors.accent};
  font-weight: 500;
  text-decoration: none;
`;

const TeamPage = () => {
  const {
    pageTitle,
    jobOpenings,
  } = data;

  return (
    <ThemeProvider themeStyle="light">
      <Layout>
        <Head title={pageTitle} />
        <Container>
          {(jobOpenings && jobOpenings.openings.length > 0) && (
            <CardContainer>
              <Title displayLevel={6}>
                {jobOpenings.title}
              </Title>
              <BodyText>
                {jobOpenings.content}
              </BodyText>
              {jobOpenings.openings.map((job, i) => {
                return (
                  <JobCard
                    key={`${job.role + i}`}
                    locations={job.locations}
                    role={job.role}
                    link={job.link}
                  />
                );
              })}
            </CardContainer>
          )}
          <About>
            <Title displayLevel={8}>
              Looking for more about us?
            </Title>
            <BodyText>
              We're currently working on our new website. In the meantime, feel free to check out <Link href="https://stoomlink.com">stoomlink.com</Link> where the latest info is available.
            </BodyText>
          </About>
        </Container>
        <Footer
          className="noBefore"
          teamLink={false}
        />
      </Layout>
    </ThemeProvider>
  );
};

export default TeamPage;
