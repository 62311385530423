import thomas from '../src/assets/images/team/thomas.jpg';
import francois from '../src/assets/images/team/francois.jpg';
import denis from '../src/assets/images/team/denis.jpg';
import mathieu from '../src/assets/images/team/mathieu.jpg';
import helene from '../src/assets/images/team/helene.jpg';

const team = {
  pageTitle: 'Home',
  title: 'Our lovely team, driven by passion and a powerful DNA.',
  members: [
    {
      firstName: 'Hélène',
      lastName: 'Ruelle',
      role: 'Frontend Engineer',
      picture: helene,
      blog: null,
      socials: [
        {
          id: 'LinkedIn',
          link: 'https://www.linkedin.com/in/helenerll/',
        },
      ],
      bio: 'Hélène is our dev whisperer & tech architect, she is everywhere, fact-checking every idea and making sure we do not do anything stupid.',
      funFact: 'Pasta al pomodorro somewhere in Italy (preferably in front of the sea, doesn’t happen much…).',
    },
    {
      firstName: 'Denis',
      lastName: 'Florkin',
      role: 'Lead Backend Engineer',
      picture: denis,
      blog: 'https://blog.stoomlink.com/denis-florkin-remote-only/',
      socials: [
        {
          id: 'LinkedIn',
          link: 'https://www.linkedin.com/in/denis-florkin-3aa03a40/',
        },
      ],
      bio: 'Denis is the real brain behind our tech, he is the hero we need but not the one we deserve.',
      funFact: '“I\'ll make my own theme park, with Blackjack, and hookers.” – Bender, on the moon',
    },
    {
      firstName: 'Thomas',
      lastName: 'Hermine',
      role: 'Head of Technologies',
      picture: thomas,
      blog: null,
      socials: [
        {
          id: 'LinkedIn',
          link: 'https://www.linkedin.com/in/thomashermine/',
        },
      ],
      bio: 'Thomas is the face of the company, the strategic thinker behind our tech, but he is also terrible at making good jokes.',
      funFact: 'As any chief, a good whisky. I hate it, it’s just to fit the image.',
    },
    {
      firstName: 'Mathieu',
      lastName: 'Delvaux',
      role: 'Designer',
      picture: mathieu,
      blog: null,
      socials: [
        {
          id: 'LinkedIn',
          link: 'https://www.linkedin.com/in/mathieudelvaux/',
        },
      ],
      bio: 'Mathieu is the reason our products are so smooth & refined. He\'s psycho-crazy for the weirdest useless details but you get used to it… sometime.',
      funFact: 'w h i t e s p a c e.',
    },
    {
      firstName: 'François',
      lastName: 'Halin',
      role: 'Head of Solutions',
      picture: francois,
      blog: null,
      socials: [
        {
          id: 'LinkedIn',
          link: 'https://www.linkedin.com/in/francois-halin/',
        },
      ],
      bio: 'François is making sure we are ticking better than a Swiss clock. He has the discipline of a five-star general and the same expressions as your grandfather (“bon bon bon”, “au jour d’aujourd\'hui”, …)!',
      funFact: 'Going so mad in techno parties my teammates wouldn’t recognize their beloved general 😈',
    },
  ],
  jobOpenings: {
    title: 'Wanna work with us?',
    openings: [
      /*{
        role: 'Backend Engineer',
        locations: ['Hybrid (90% remote)', 'Brussels, Belgium'],
        link: '/backend-engineer',
      },*/
      /*{
        role: 'Javascript Intern',
        locations: ['Remote', 'Brussels, Belgium'],
        link: '/javascript-intern',
      },*/
      {
        role: 'Customer Success Manager (IT Operations)',
        locations: ['Hybrid (80% remote, 20% on-site)', 'Brussels, Belgium'],
        link: '/customer-success-manager',
      }
    ],
  },
};

export default team;
